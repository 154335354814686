exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-error-page-jsx": () => import("./../../../src/templates/error-page.jsx" /* webpackChunkName: "component---src-templates-error-page-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-latest-news-jsx": () => import("./../../../src/templates/latest-news.jsx" /* webpackChunkName: "component---src-templates-latest-news-jsx" */),
  "component---src-templates-product-landing-jsx": () => import("./../../../src/templates/product-landing.jsx" /* webpackChunkName: "component---src-templates-product-landing-jsx" */)
}

